import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "l-container" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = {
  key: 0,
  class: "card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_Button = _resolveComponent("Button")!
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_AccordionScoreList = _resolveComponent("AccordionScoreList")!
  const _component_ActivityChart = _resolveComponent("ActivityChart")!
  const _component_EventDetailCard = _resolveComponent("EventDetailCard")!

  return (!_ctx.event)
    ? (_openBlock(), _createBlock(_component_LoadingComponent, {
        key: 0,
        "spinner-color": "lavender-700"
      }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        _createVNode(_component_HeadingComponent, {
          title: _ctx.title,
          breadcrumb: _ctx.breadcrumb
        }, {
          left: _withCtx(() => [
            _createVNode(_component_Button, {
              class: "p-button p-button-only-icon",
              onClick: _ctx.onGoBack
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createElementVNode("i", {
                  class: "icon-chevron_left",
                  "aria-hidden": "true",
                  "aria-label": ""
                }, null, -1)
              ])),
              _: 1
            }, 8, ["onClick"]),
            _createElementVNode("img", {
              src: _ctx.iconEventUrl,
              alt: ""
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        }, 8, ["title", "breadcrumb"]),
        _createElementVNode("main", _hoisted_2, [
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_LoadingComponent, {
                key: 0,
                spinnerColor: "lavender-700"
              }))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["p-grid--details-activity", { 'p-grid--details-activity--loading': _ctx.isLoading }])
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_AccordionScoreList, {
                    title: _ctx.glucoseTitle,
                    statistics: _ctx.glucoseStatistics?.statistics
                  }, null, 8, ["title", "statistics"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_ActivityChart, {
                    chartStart: _ctx.eventChartStart,
                    chartEnd: _ctx.eventChartEnd,
                    title: _ctx.chartTitle,
                    events: [_ctx.event],
                    "glucose-statistics": _ctx.glucoseStatistics?.statistics,
                    "heart-rate-statistics": _ctx.heartRateStatistics
                  }, null, 8, ["chartStart", "chartEnd", "title", "events", "glucose-statistics", "heart-rate-statistics"])
                ]),
                (_ctx.event.type == _ctx.EventType.TRAINING)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      _createVNode(_component_HeadingComponent, {
                        title: _ctx.$t('wearables.eventsCard.title'),
                        "sub-heading": true,
                        divider: false
                      }, null, 8, ["title"]),
                      (_openBlock(), _createBlock(_component_EventDetailCard, {
                        key: _ctx.event.id,
                        event: _ctx.event,
                        "is-clickable": false
                      }, null, 8, ["event"]))
                    ]))
                  : _createCommentVNode("", true)
              ], 2))
        ])
      ], 64))
}