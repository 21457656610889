
import { EventDto, EventStatisticsDto, EventType } from '@/models/Event';
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import { useRoute } from 'vue-router';
import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import AccordionScoreList from '@/components/wearables/common/AccordionScoreList.vue';
import ActivityChart from '@/components/wearables/activity/ActivityChart.vue';
import EventDetailCard from '@/components/wearables/activity/EventDetailCard.vue';
import { eventService } from '@/services/EventService';
import { measurementService } from '@/services/MeasurementService';
import { ServiceError } from '@/services/util/ServiceError';
import { useI18n } from 'vue-i18n';
import router from '@/router';
export default defineComponent({
  components: {
    HeadingComponent,
    LoadingComponent,
    AccordionScoreList,
    ActivityChart,
    EventDetailCard,
  },
  setup() {
    const route = useRoute();
    const patientId = route.params.patientId as string;
    const event = ref<EventDto>();
    const { t } = useI18n();
    const isLoading = ref(true);
    const eventId = route.params.eventId as string;
    const breadcrumb = ref();
    const glucoseStatistics: Ref<EventStatisticsDto | undefined> = ref();
    const heartRateStatistics = ref();
    const eventChartStart = computed(() => (glucoseStatistics.value ? glucoseStatistics.value.startDateChart : ''));
    const eventChartEnd = computed(() => (glucoseStatistics.value ? glucoseStatistics.value.endDateChart : ''));
    const title = ref();
    const chartTitle = computed(() => {
      if (event.value?.type === EventType.TRAINING) {
        return t('wearables.eventDetails.chartTitle');
      }
      return t('wearables.eventDetails.chartEventTitle');
    });

    const glucoseTitle = computed(() => {
      if (event.value?.type === EventType.TRAINING) {
        return t('wearables.eventDetails.glucoseScoreTitle');
      }
      return t('wearables.eventDetails.glucoseEventScoreTitle');
    });

    const iconsTrainningUrlBase = ref(window.location.origin + '/assets/icon/wearables/sports/');
    const iconTrainningEventUrl = computed(() => {
      if (event.value?.subType) {
        return iconsTrainningUrlBase.value + event.value.subType + '.svg';
      }
      return '';
    });

    const iconMealEventUrl = ref(window.location.origin + '/assets/icon/wearables/meal.svg');

    const iconEventUrl = computed(() => {
      if (event.value?.type === EventType.TRAINING) {
        return iconTrainningEventUrl.value;
      }
      return iconMealEventUrl.value;
    });

    const getBreadcrumb = () => {
      if (event.value?.type === EventType.TRAINING) {
        getTrainingBreadcrumb();
      } else {
        getMealBreadcrumb();
      }
    };

    function getTrainingBreadcrumb() {
      breadcrumb.value = [
        { label: t('breadcrumb.followUp'), to: `/patient/${patientId}/follow-up/activity` },
        { label: t('breadcrumb.activity'), to: `/patient/${patientId}/follow-up/activity` },
        { label: t('breadcrumb.event-details'), to: '#', disabled: true },
      ];
    }

    function getMealBreadcrumb() {
      breadcrumb.value = [
        { label: t('breadcrumb.followUp'), to: `/patient/${patientId}/follow-up/nutrition` },
        { label: t('breadcrumb.nutrition'), to: `/patient/${patientId}/follow-up/nutrition` },
        { label: t('breadcrumb.event-details'), to: '#', disabled: true },
      ];
    }

    onMounted(async () => {
      isLoading.value = true;
      heartRateStatistics.value = undefined;
      glucoseStatistics.value = undefined;
      await getEventDetails();
      if (event.value) {
        getBreadcrumb();
        await fetchStatisticsGlucose();
        await fetchStatisticsHeartRate();
      }
      isLoading.value = false;
    });

    const getEventDetails = async () => {
      const eventData = await eventService.find(eventId, patientId);
      if (!(eventData instanceof ServiceError)) {
        event.value = eventData;
        title.value =
          event.value.type == EventType.TRAINING ? t(`events.subtype.${eventData.subType}`) : eventData.name;
      }
    };

    const fetchStatisticsGlucose = async () => {
      if (!event.value) {
        return;
      }

      if (event.value.type == EventType.TRAINING && (event.value.date == null || event.value.endDate == null)) {
        return;
      }

      const result = await eventService.findStatistics(eventId, patientId);
      if (!(result instanceof ServiceError)) {
        glucoseStatistics.value = result;
      }
    };

    const fetchStatisticsHeartRate = async () => {
      if (
        !glucoseStatistics.value ||
        glucoseStatistics.value.startDateChart == null ||
        glucoseStatistics.value.endDateChart == null
      ) {
        return;
      }

      const result = await measurementService.findHeartRateStatistics(
        patientId,
        glucoseStatistics.value.startDateChart,
        glucoseStatistics.value.endDateChart,
      );

      if (!(result instanceof ServiceError)) {
        heartRateStatistics.value = result;
      }
    };

    async function onGoBack() {
      if (window.history.state.back != '') {
        router.go(-1);
      } else {
        if (event.value?.type === EventType.TRAINING) {
          await router.push(`/patient/${patientId}/follow-up/activity`);
        } else {
          await router.push(`/patient/${patientId}/follow-up/nutrition`);
        }
      }
    }

    return {
      event,
      EventType,
      patientId,
      breadcrumb,
      iconEventUrl,
      title,
      onGoBack,
      isLoading,
      glucoseStatistics,
      heartRateStatistics,
      eventChartStart,
      eventChartEnd,
      chartTitle,
      glucoseTitle,
    };
  },
});
