import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-events-container__item-header" }
const _hoisted_2 = { class: "c-events-container__item-type" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "c-events-container__item-dates" }
const _hoisted_5 = { class: "c-events-container__item-date" }
const _hoisted_6 = { class: "c-events-container__item-date" }
const _hoisted_7 = { class: "c-events-container__item-body" }
const _hoisted_8 = { class: "c-events-container__item-data" }
const _hoisted_9 = { class: "c-events-container__item-data" }
const _hoisted_10 = { class: "c-events-container__item-data" }
const _hoisted_11 = { class: "c-events-container__item-data" }
const _hoisted_12 = { class: "c-events-container__item-data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["c-events-container__item", { clickable: _ctx.isClickable }]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleCardClick && _ctx.handleCardClick(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _ctx.iconEventUrl,
          alt: ""
        }, null, 8, _hoisted_3),
        _createElementVNode("p", null, _toDisplayString(_ctx.event?.subType ? _ctx.$t('events.subtype.' + _ctx.event?.subType) : ''), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_ctx.eventStart), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.eventsCard.start')), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", null, _toDisplayString(_ctx.eventEnd), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.eventsCard.end')), 1)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("p", null, _toDisplayString(_ctx.eventDuration), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.eventsCard.duration')), 1)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("p", null, _toDisplayString(_ctx.event?.calories ? Math.round((_ctx.event?.calories / 1000) * 100) / 100 : '-'), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.eventsCard.kcal')), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("p", null, _toDisplayString(_ctx.event?.distance ? Math.round((_ctx.event?.distance / 1000) * 100) / 100 : '-'), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.eventsCard.km')), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("p", null, _toDisplayString(_ctx.event?.avgHeartRate ?? '-'), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.eventsCard.ppmMed')), 1)
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("p", null, _toDisplayString(_ctx.event?.maxHeartRate ?? '-'), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('wearables.eventsCard.ppmMax')), 1)
      ])
    ])
  ], 2))
}