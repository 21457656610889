
import dateFormat from '@/helpers/DateFormat.helper';
import { EventScoreDto } from '@/models/Event';
import 'chartjs-adapter-moment';
import { defineComponent, PropType, computed, ref } from 'vue';
import moment from 'moment';

export default defineComponent({
  emits: ['on-event-click'],
  props: {
    title: {
      type: String,
      default: '',
    },
    event: {
      type: Object as PropType<EventScoreDto>,
    },
    isClickable: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const iconsUrlBase = ref(window.location.origin + '/assets/icon/wearables/sports/');
    const iconEventUrl = computed(() => {
      if (props.event?.subType) {
        return iconsUrlBase.value + props.event.subType + '.svg';
      }
      return '';
    });
    const eventDuration = computed(() => {
      if (props.event?.date && props.event?.endDate) {
        const { date, endDate } = props.event;
        const start = moment(date);
        const end = moment(endDate);
        const duration = moment.duration(end.diff(start));
        const hours = duration.hours();
        const minutes = duration.minutes();
        return `${hours}h ${minutes}m`;
      }
      return '-';
    });

    const eventStart = computed(() => {
      return props.event?.date ? moment(dateFormat.utcToUserTimezone(props.event.date)).format('HH:mm') : '-';
    });

    const eventEnd = computed(() => {
      return props.event?.endDate ? moment(dateFormat.utcToUserTimezone(props.event.endDate)).format('HH:mm') : '-';
    });

    const handleCardClick = () => {
      if (!props.isClickable) return;
      emit('on-event-click', props.event?.id);
    };

    return {
      dateFormat,
      eventDuration,
      iconEventUrl,
      eventStart,
      eventEnd,
      handleCardClick,
    };
  },
});
