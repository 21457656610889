import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_MultiChart = _resolveComponent("MultiChart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeadingComponent, {
      title: _ctx.title,
      "sub-heading": true,
      divider: false
    }, null, 8, ["title"]),
    (_ctx.showEmptyState)
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 0,
          icon: "icon-confused",
          text: _ctx.$t('wearables.sleepChart.noData'),
          textAtBottom: true
        }, null, 8, ["text"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MultiChart, {
            chartId: _ctx.chartId,
            glucoseSerie: _ctx.glucoseSerie,
            heartRateSerie: _ctx.heartRateSerie,
            chartStart: _ctx.chartStart,
            chartEnd: _ctx.chartEnd,
            events: _ctx.eventsFiltered,
            onAnnotationEventClick: _ctx.handleAnnotationEventClick
          }, null, 8, ["chartId", "glucoseSerie", "heartRateSerie", "chartStart", "chartEnd", "events", "onAnnotationEventClick"])
        ]))
  ], 64))
}